import { Box, Button, CircularProgress, Modal, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { SessionFiles } from "../../components/Gobierno corporativo/SessionComponent/SessionFiles";
import {
   CreateCommitment,
   sendExternalUsersCommitmentsEmails,
   UpdateCompletedSession,
   UpdateExternalUserName,
   UpdateUserAttendance,
   useGetResourceSelected,
} from "../../lib/gobCorpBEClient";
import { UserContext } from "../../context/userContext";
import { SessionNotes } from "../../components/Gobierno corporativo/SessionComponent/SessionNotes";
import { SessionMembers } from "../../components/Gobierno corporativo/SessionComponent/SessionMembers";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { SessionCommitments } from "../../components/Gobierno corporativo/SessionComponent/SessionCommitments";
import { Bill } from "../../components/Gobierno corporativo/Bill/Bill";
import {
   complaintUploadPdfEvidence,
   CreateFileByNameNoMutate,
   getFileByGovernanceAndFoldername,
   getFoldersAndFilesById,
   getUrlS3,
   getUrlS3GC,
} from "../../lib/usersBEClient";
import { VoteModal } from "../../components/Gobierno corporativo/SessionComponent/VoteModal";
import { useNavigate } from "react-router-dom";
import { SessionVerifyMembers } from "../../components/Gobierno corporativo/SessionComponent/SessionVerifyMembers";
import { SignModal } from "../../components/Gobierno corporativo/SessionComponent/SignModal";
import { BillPDF } from "../../components/Gobierno corporativo/Bill/BillPDF/BillPDF";
import { pdf } from "@react-pdf/renderer";
import { GovernanceSessionContext } from "../../context/governanceContext/governanceSessionContext";
import { ManifestoModal } from "../../components/Gobierno corporativo/SessionComponent/ManifestoModal";
import { BillAssemblePDF } from "../../components/Gobierno corporativo/Bill/BillPDF/BillAssemblePDF";
import { getBase64Image, getContrastYIQ } from "../../const/globalConst";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { CancelSessionModal } from "../../components/Gobierno corporativo/SessionComponent/CancelSessionModal";
import NewVotesModal from "../../components/Gobierno corporativo/SessionComponent/SubComponents/sessionVotationComponents/NewVoteModal";
import ErrorIcon from "@mui/icons-material/Error";
import { GovernanceTheSequelContext } from "../../context/governanceContext/governanceTheSequelContext";
import { logo_Isotipo } from "../../assets/icons/DrawerAppScreenIcons";
import { LoadingButton } from "../../components/LoadingButton";

const GetSessionScreenData = (
   user,
   setOpenModal,
   setOpenModalCreateModal,
   setWaitingToVerify,
   setUserRejected,
   setVotationOnCourse,
   setDisabledPdf,
   waitingToVerify
) => {
   const [openModalToSign, setOpenModalToSign] = useState(false);
   const [ableToLeave, setAbleToLeave] = useState(false);
   const [timer, setTimer] = useState(59);
   const { setHideSecondTopMenu } = useContext(GovernanceContext);
   const {
      socket,
      state,
      dispatch,
      setMembersToVerify,
      setInSession,
      session,
      isLoadingSession,
      setSessionFound,
      setSessionFoundArray,
      setHideNotification,
      setIsLoadingSession,
      membersWithCharge,
      setIsSocketConnected,
      isSocketConnected,
      setSession,
      setSessionSeed,
      setAdditionalVotesSeed,
   } = useContext(GovernanceSessionContext);
   const { governanceCoordinators, fetchCords } = useContext(GovernanceTheSequelContext);

   const handleClick = () => {
      setOpenModal(true);
   };

   const hasPermissions = useMemo(() => {
      if (isLoadingSession) return;
      let userCharges = membersWithCharge.find((member) => member._id === user.id);
      if (!userCharges) return;
      if (typeof userCharges.memberCharge === "string")
         return (userCharges.memberCharge as string).toLowerCase().includes("coordinador");
      const hasUserCharges = userCharges.memberCharge.some(
         (charge) =>
            charge.toLowerCase().includes("secretario") ||
            charge.toLowerCase().includes("presidente") ||
            charge.toLowerCase().includes("coordinador") ||
            charge.includes("Usuario de implementacion")
      );
      if (hasUserCharges && waitingToVerify) {
         if (!session?.usersRegistry.find((userRegistry) => userRegistry.user === user.id).attended)
            UpdateUserAttendance(session.company, session._id, user.id).then((data) => data);
         socket.emit("verify-request", { userId: user.id, access: true, sessionId: session._id });
      }
      return hasUserCharges;
   }, [membersWithCharge, isLoadingSession]);

   const socketHandler = (valuesFromSocket) => {
      if (!valuesFromSocket.orderId) dispatch({ type: "addNewAdditionalVote", newAdditionalVote: valuesFromSocket });
      dispatch({ type: "setActiveVote", activeVote: valuesFromSocket });
      setOpenModalCreateModal(true);
   };

   const socketHandleAffairVotations = (valuesFromSocket) => {
      setVotationOnCourse(true);
      if (valuesFromSocket.vote) dispatch({ type: "addNewAdditionalVote", newAdditionalVote: valuesFromSocket.vote });
      let userInVote = false;
      if (valuesFromSocket.vote)
         userInVote = valuesFromSocket.vote.votes.some((votationUser) => votationUser.userId === user.id);
      else if (session.group && valuesFromSocket.company) {
         userInVote = valuesFromSocket.order.users.some((votationUser) => votationUser.user === user.id);
      } else userInVote = valuesFromSocket.order.users.some((votationUser) => votationUser.user === user.id);
      const isCoordinator = governanceCoordinators.some((govUseruser) => govUseruser.user._id === user.id);
      if (!userInVote && !isCoordinator) return;
      dispatch({
         type: "setActiveVote",
         activeVote: valuesFromSocket.vote || valuesFromSocket.order,
         ...(valuesFromSocket.company && { company: valuesFromSocket.company }),
      });
      if (userInVote) setOpenModalCreateModal(true);
   };

   const socketSocketJoinHandler = async (valuesFromSocket) => {
      console.log({ joinRequest: valuesFromSocket });
      if (valuesFromSocket.sessionId !== session._id) return;
      if (
         session?.usersRegistry.find((userRegistry) => userRegistry.user === valuesFromSocket.user.id).attended &&
         valuesFromSocket.request !== "sign"
      )
         return setInSession(true);
      let hasUserCharges = false;
      const userCharges = membersWithCharge.find((member) => member._id === valuesFromSocket.user.id);
      if (userCharges) {
         if (typeof userCharges?.memberCharge === "string")
            return (userCharges.memberCharge as string).toLowerCase().includes("coordinador");
         hasUserCharges = userCharges?.memberCharge.some(
            (charge) =>
               charge.toLowerCase().includes("secretario") ||
               charge.toLowerCase().includes("presidente") ||
               charge.toLowerCase().includes("coordinador") ||
               charge.includes("Usuario de implementacion")
         );
      }
      if (governanceCoordinators.length === 0) await fetchCords();
      const isCoordinator = governanceCoordinators.some((user) => user.user._id === valuesFromSocket.user.id);
      if ((!hasUserCharges && !isCoordinator) || valuesFromSocket.request === "sign")
         setMembersToVerify((current) => [...current, valuesFromSocket]);
   };

   const socketSocketJoinExternalHandler = (valuesFromSocket) => {
      if (valuesFromSocket.sessionId !== session._id) return;
      setMembersToVerify((current) => [...current, valuesFromSocket]);
   };

   const socketSocketVerifiedHandler = (valuesFromSocket) => {
      if (user.id === valuesFromSocket.userId) {
         if (valuesFromSocket.access) {
            setWaitingToVerify(false);
            setInSession(true);
            setUserRejected(false);
         } else {
            if (valuesFromSocket) setWaitingToVerify(false);
            setUserRejected(true);
         }
         setIsLoadingSession(false);
      }
   };

   const socketModalToSign = (valuesFromSocket) => {
      if (!valuesFromSocket.signArray.includes(user.id)) setOpenModalToSign(true);
   };

   const socketHandlerCloseVoteModal = (valuesFromSocket) => {
      if (valuesFromSocket.order) {
         dispatch({ type: "updateAffairVoteInVoteStatus", orderInfo: valuesFromSocket.order });
         setSessionSeed((s) => s + 1);
      }
      if (valuesFromSocket.addVote)
         dispatch({
            type: "overWriteAdditionalVote",
            additionalVote: valuesFromSocket.addVote,
         });
      dispatch({ type: "deleteActiveVote" });
      setOpenModal(false);
      setOpenModalCreateModal(false);
      setVotationOnCourse(false);
   };

   const handlerMembersWithoutVerify = (valuesFromSockets) => {
      setMembersToVerify(valuesFromSockets);
   };

   const handlerKiller = () => {
      setDisabledPdf(false);
   };

   const handlerCompleteKiller = () => {
      setAbleToLeave(true);
   };

   const handlerCloseVotation = (valuesFromSocket) => {
      if (!valuesFromSocket.isAffairVote)
         dispatch({ type: "overWriteAdditionalVote", additionalVote: valuesFromSocket.additionalVote });
      dispatch({ type: "deleteActiveVote" });
      setAdditionalVotesSeed((s) => s + 1);
      setOpenModalCreateModal(false);
      setOpenModal(false);
      setVotationOnCourse(false);
   };

   const handleCloseAffairVotation = (valuesFromSocket) => {
      setSession(valuesFromSocket.session);
      dispatch({ type: "additionalVotesUpdateAll", additionalVotes: valuesFromSocket.vote });
      dispatch({ type: "deleteActiveVote" });
      setOpenModalCreateModal(false);
      setOpenModal(false);
      setVotationOnCourse(false);
   };

   const requestVotation = () => {
      if (state.vote && hasPermissions) socket.emit("update-votation-state", session._id, state.vote);
   };

   const handleUpdateVote = (voteFromSocket) => {
      if (!voteFromSocket.votes.some((vote) => vote.userId === user.id)) return;
      if (state.vote === null) {
         dispatch({ type: "setActiveVote", activeVote: voteFromSocket });
         setOpenModalCreateModal(true);
      }
   };

   const updateExternalNames = async (values) => {
      await UpdateExternalUserName(session._id, values.userId, values.firstName);
      session.externs.find((u) => u.user === values.userId).name = values.firstName;
   };

   useEffect(() => {
      if ((socket === null && isSocketConnected === false) || isLoadingSession) return;

      socket.on("connect_error", (err) => {
         setIsSocketConnected(false);
      });
      socket.on("disconnect", () => {
         console.log("socket has been disconnected");
         if (!socket.connected) setIsSocketConnected(false);
      });

      socket.on("receive-votation", socketHandler);

      socket.on("receive-affair-votation", socketHandleAffairVotations);

      socket.on("wait-verify", socketSocketJoinHandler);

      socket.on("wait-verify-external", socketSocketJoinExternalHandler);

      socket.on("request-verified", socketSocketVerifiedHandler);

      socket.on("require-sign", socketModalToSign);

      socket.on("close-vote-modal", socketHandlerCloseVoteModal);

      socket.on("fetch-members-without-verify", handlerMembersWithoutVerify);

      socket.on("killer", handlerKiller);

      socket.on("complete-killer", handlerCompleteKiller);

      socket.on("close-votation", handlerCloseVotation);

      socket.on("close-affair-votation", handleCloseAffairVotation);

      socket.on("request-votation", requestVotation);

      socket.on("update-vote", handleUpdateVote);

      socket.on("update-external-users", updateExternalNames);

      return () => {
         socket.off("receive-votation", socketHandler);
         socket.off("receive-affair-votation", socketHandleAffairVotations);
         socket.off("wait-verify", socketSocketJoinHandler);
         socket.off("wait-verify-external", socketSocketJoinExternalHandler);
         socket.off("request-verified", socketSocketVerifiedHandler);
         socket.off("close-vote-modal", socketHandlerCloseVoteModal);
         socket.off("require-sign", socketModalToSign);
         socket.off("fetch-members-without-verify", handlerMembersWithoutVerify);
         socket.off("killer", handlerKiller);
         socket.off("complete-killer", handlerKiller);
         socket.off("close-votation", handlerCloseVotation);
         socket.off("close-affair-votation", handleCloseAffairVotation);
         socket.off("update-external-users", updateExternalNames);
      };
   }, [socket, isLoadingSession]);

   useEffect(() => {
      setInSession(true);
   }, [setInSession]);

   useEffect(() => {
      if (session?._id && !isLoadingSession) {
         socket.emit("request-votation", session._id);
      }
   }, [session, isLoadingSession]);

   useEffect(() => {
      const interval = setInterval(() => {
         if (timer === 0) {
            clearInterval(interval);
         } else {
            setTimer((prevContador) => prevContador - 1);
         }
      }, 1000);
      return () => clearInterval(interval);
   }, [timer]);

   useEffect(() => {
      setHideSecondTopMenu(false);
      return () => {
         setHideSecondTopMenu(true);
         setSessionFound(null);
         setSessionFoundArray([]);
         setHideNotification(false);
      };
   }, []);

   return {
      handleClick,
      openModalToSign,
      setOpenModalToSign,
      ableToLeave,
      timer,
      setTimer,
      hasPermissions,
   };
};

export const SessionScreen = () => {
   const { companySelected } = useContext(GovernanceContext);
   const {
      valuesFromBill,
      colors,
      pendingVotes,
      setFinishHour,
      isLoadingSession,
      membersWithCharge,
      session,
      socket,
      sessionFound,
      governingBody,
      affairsArray,
      alreadySign,
      externalMemberUsers,
      isSocketConnected,
      groupCompaniesInSession,
      state,
      additionalVotesSeed,
      sessionResolutions,
      isLoadingBill,
      signArray,
      userOnlineSigns,
   } = useContext(GovernanceSessionContext);

   const { user } = useContext(UserContext);
   const [openModal, setOpenModal] = useState(false);
   const [openModalCreateModal, setOpenModalCreateModal] = useState(false);
   const [waitingToVerify, setWaitingToVerify] = useState(true);
   const [userRejected, setUserRejected] = useState(false);
   const [missingSigns, setMissingSigns] = useState(null);
   const [manifiestoModalOpen, setManifiestoModalOpen] = useState(false);
   const [openConfirmCancelSession, setOpenConfirmCancelSession] = useState(false);
   const resourceId = useGetResourceSelected();
   const { mutate: createCommitment } = CreateCommitment(companySelected, resourceId);
   const [votationOnCourse, setVotationOnCourse] = useState(false);
   const [disabledPdf, setDisabledPdf] = useState(true);
   const [loadingFinishedSession, setLoadingFinishedSession] = useState(false);

   const myPendingVotes = useMemo(() => {
      return pendingVotes.filter((vote) =>
         vote.votes.some((userVote) => userVote.userId === user.id && !userVote.answer && !userVote.abstention)
      );
   }, [pendingVotes, pendingVotes.length, additionalVotesSeed, user.id]);

   const { mutate } = UpdateCompletedSession();
   const navigate = useNavigate();
   const { handleClick, openModalToSign, setOpenModalToSign, ableToLeave, timer, setTimer, hasPermissions } =
      GetSessionScreenData(
         user,
         setOpenModal,
         setOpenModalCreateModal,
         setWaitingToVerify,
         setUserRejected,
         setVotationOnCourse,
         setDisabledPdf,
         waitingToVerify
      );
   useEffect(() => {
      const checkUserAttendance = () => {
         const attended = session?.usersRegistry.find((userRegistry) => userRegistry.user === user.id).attended;
         if (attended) socket.emit("verify-request", { userId: user.id, access: true, sessionId: session._id });
         return !attended;
      };
      if (isLoadingSession) return;
      if (session?.usersRegistry) setWaitingToVerify(checkUserAttendance());
   }, [isLoadingSession, session?.usersRegistry, user.id]);

   const uploadFile = async (blob, company: string = undefined) => {
      const response = await CreateFileByNameNoMutate({
         file: {
            name: `M${session.billCode}-${session.title}.pdf`,
            owner: user.id,
            metadata: [],
            size: blob.size,
            type: blob.type,
            fileDirection: `gc/companies/${session.company}/governing-body/${session.governance}/files/`,
            governingBody: session.governance,
         },
         folder: {
            name: session.assembly ? "Minutas" : "Convocatoria y minuta de reuniones",
            governance: session.governance,
            company: company,
         },
      });
      if (response) {
         await complaintUploadPdfEvidence(response.urlToUpload, blob);
         if (!company) handleLeaveSession();
      }
   };

   const createPDF = async (commitmentData = null, cancelled = null) => {
      const logoUrl = await getUrlS3("images-lecosy", { folder: session.company }, "logo.png");
      const urlImages = {};
      for (const user of signArray) {
         const imageUrl = await getUrlS3GC(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}` },
            `sign-${user}.png`
         );
         try {
            urlImages[user] = await getBase64Image(imageUrl);
         } catch (err) {
            console.log({ err });
            urlImages[user] = imageUrl;
         }
      }
      const usersList = signArray.reduce((obj, key) => {
         const userOnline = membersWithCharge.find((user) => user._id === key);
         const externUser = externalMemberUsers.find((userOnline) => userOnline.user === key);
         if (userOnline)
            return [
               ...obj,
               {
                  userId: key,
                  userName: `${userOnline.firstName} ${userOnline.lastName}`,
                  sign: urlImages[key],
               },
            ];
         else if (externUser)
            return [
               ...obj,
               {
                  userId: key,
                  userName: `${externUser.name}`,
                  sign: urlImages[key],
               },
            ];
         return obj;
      }, []);

      let blob;
      if (session.assembly) {
         blob = await pdf(
            <BillAssemblePDF
               signsArray={usersList}
               session={session}
               members={membersWithCharge}
               values={valuesFromBill}
               additionalVotes={state.additionalVotes || []}
               affairVotes={state.affairVotations || []}
               sessionResolutions={sessionResolutions}
               affairsArray={affairsArray}
               governingBody={governingBody}
               colors={colors}
               colorsText={{ primary: getContrastYIQ(colors.primary), secondary: getContrastYIQ(colors.secondary) }}
               companyLogo={logoUrl}
               cancelled={cancelled}
               externalMembers={externalMemberUsers.concat(
                  membersWithCharge.filter((member) => {
                     if (member.firstName)
                        if (
                           member.memberCharge.includes("Coordinador del Gobierno Corporativo") &&
                           member.memberCharge.length === 1
                        )
                           return true;
                     return false;
                  }) as any
               )}
            />
         ).toBlob();
      } else {
         blob = await pdf(
            <BillPDF
               signsArray={usersList}
               values={valuesFromBill}
               members={membersWithCharge}
               session={session}
               commitmentsArray={valuesFromBill["commitmentsArray"]}
               notes={valuesFromBill[`notes`]}
               comment={valuesFromBill[`comment`]}
               affairVotes={state.affairVotations || []}
               sessionResolutions={sessionResolutions}
               affairsArray={affairsArray}
               additionalVotes={state.additionalVotes || []}
               colors={colors}
               colorsText={{ primary: getContrastYIQ(colors.primary), secondary: getContrastYIQ(colors.secondary) }}
               governingBodyName={governingBody.title.toUpperCase()}
               companyLogo={logoUrl}
               cancelled={cancelled}
               externalUsers={externalMemberUsers.concat(
                  membersWithCharge.filter((member) => {
                     if (member.firstName)
                        if (
                           member.memberCharge.includes("Coordinador del Gobierno Corporativo") &&
                           member.memberCharge.length === 1
                        )
                           return true;
                     return false;
                  }) as any
               )}
            />
         ).toBlob();
      }
      await uploadFile(blob);
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "M" + session.billCode + ".pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      window.open(URL.createObjectURL(blob), "_blank");
   };

   const createGroupSessionPDF = async (commitmentData = null, cancelled = null) => {
      const urlImages = {};
      for (const user of signArray) {
         const imageUrl = await getUrlS3GC(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/sessions/${session._id}` },
            `sign-${user}.png`
         );
         try {
            urlImages[user] = await getBase64Image(imageUrl);
         } catch (err) {
            console.log({ err });
            urlImages[user] = imageUrl;
         }
      }
      const usersList = signArray.reduce((obj, key) => {
         const userOnline = membersWithCharge.find((user) => user._id === key);
         const externUser = externalMemberUsers.find((userOnline) => userOnline.user === key);
         if (userOnline)
            return [
               ...obj,
               {
                  userId: key,
                  userName: `${userOnline.firstName} ${userOnline.lastName}`,
                  sign: urlImages[key],
               },
            ];
         else if (externUser)
            return [
               ...obj,
               {
                  userId: key,
                  userName: `${externUser.name}`,
                  sign: urlImages[key],
               },
            ];
         return obj;
      }, []);
      for (const company of groupCompaniesInSession) {
         const filteredAffairs = affairsArray.filter((affair) => affair.companies.some((c) => c === company._id));
         const companyComments = [
            ...valuesFromBill[`comment`],
            ...(valuesFromBill[`comment${company._id}`] ? valuesFromBill[`comment${company._id}`] : []),
         ];
         const filteredCommitments =
            valuesFromBill["commitmentsArray"].filter((c) => c.companies.some((com) => company._id === com._id)) || [];
         let logoUrl = logo_Isotipo;
         try {
            logoUrl = await getUrlS3("images-lecosy", { folder: company._id }, "logo.png");
         } catch (err) {}
         const companyAffairVotations = state.affairVotations.reduce((array, votation) => {
            const foundCompany = votation.companies.find((votationCompany) => votationCompany.company === company._id);
            if (!foundCompany) return [...array];
            return [...array, { affair: votation.affair, ...foundCompany }];
         }, []);
         const blob = await pdf(
            <BillPDF
               signsArray={usersList}
               affairVotes={companyAffairVotations || []}
               sessionResolutions={sessionResolutions.filter((resolution) => resolution.company === company._id)}
               values={valuesFromBill}
               members={membersWithCharge}
               session={session}
               commitmentsArray={filteredCommitments}
               notes={valuesFromBill[`notes${company._id}`] || ""}
               comment={companyComments}
               affairsArray={filteredAffairs}
               additionalVotes={state.additionalVotes.filter(
                  (addVote) => addVote.company === company._id || !addVote.company
               )}
               colors={{
                  primary: company?.company_details?.primaryColor,
                  secondary: company?.company_details?.secondaryColor,
               }}
               colorsText={{
                  primary: getContrastYIQ(company?.company_details?.primaryColor),
                  secondary: getContrastYIQ(company?.company_details?.secondaryColor),
               }}
               governingBodyName={governingBody.title.toUpperCase()}
               companyLogo={logoUrl}
               cancelled={cancelled}
               externalUsers={externalMemberUsers.concat(
                  membersWithCharge.filter((member) => {
                     if (member.firstName)
                        if (
                           member.memberCharge.includes("Coordinador del Gobierno Corporativo") &&
                           member.memberCharge.length === 1
                        )
                           return member;
                  }) as any
               )}
            />
         ).toBlob();
         await uploadFile(blob, company.person_details.comercialName);
         const url = URL.createObjectURL(blob);
         const a = document.createElement("a");
         a.href = url;
         a.download = "M" + session.billCode + ".pdf";
         document.body.appendChild(a);
         a.click();
         window.URL.revokeObjectURL(url);
         document.body.removeChild(a);
         window.open(URL.createObjectURL(blob), "_blank");
      }
   };

   const handleCreateCommitmentAndPDF = async (data = null) => {
      let commitmentData = null;
      if (data) {
         commitmentData = data.map((commitment) => {
            return {
               title: commitment.title,
               users: commitment.users,
               dueDate: commitment.dueDate,
            };
         });
      }

      if (session.group) await createGroupSessionPDF(commitmentData);
      else await createPDF(commitmentData);

      socket.emit("end-session-and-delete-values", session._id);
      sendExternalUsersCommitmentsEmails(session._id);
      //deleteFolderS3("files-lecosy", `gc/companies/${session.company}/governing-body/${session._id}/signs`);
      if (!session.completed) {
         mutate(
            { completed: true, sessionId: session._id },
            {
               onError: (error) => {
                  console.log(error);
                  showSnackBar("Error al cerrar session.", true);
               },
            }
         );
         setLoadingFinishedSession(false);
         handleLeaveSession();
      }
   };

   const handleEndSession = async () => {
      setFinishHour(new Date());
      let allMembersSign = true;
      const officialIdList = userOnlineSigns.map((user) => user._id);
      for (const officialID of officialIdList) {
         allMembersSign = signArray.includes(officialID);
         if (!allMembersSign) break;
      }
      if (!allMembersSign) {
         setMissingSigns(true);
         socket.emit("end-session", { sessionId: session._id, signArray });
      } else {
         setLoadingFinishedSession(true);
         socket.emit("complete-session", session._id);
         const secretaryAndPresidentIds = membersWithCharge.filter((userWithCharge) =>
            userWithCharge.memberCharge.some((charge) => {
               return (
                  charge.toLowerCase().includes("presidente") ||
                  charge.toLowerCase().includes("secretario") ||
                  charge.toLowerCase().includes("coordinador")
               );
            })
         );
         if (valuesFromBill["meetingUrl"]) {
            const commitmentObjectMeetingUrl = {
               users: secretaryAndPresidentIds.map((userWithCharge) => userWithCharge._id),
               title: session.title + ": Link de acceso a grabación",
               dueDate: new Date(3000, 0, 1),
               session: session._id,
            };
            createCommitment(commitmentObjectMeetingUrl, {
               onError: () => {
                  showSnackBar("Error al crear el acuerdo.", true);
               },
               onSuccess: async (dataMeetingUrl) => {
                  dataMeetingUrl.users = secretaryAndPresidentIds;
                  handleCreateCommitmentAndPDF([dataMeetingUrl]);
               },
            });
         } else {
            handleCreateCommitmentAndPDF();
         }
      }
   };
   const getUploadedSessionBill = async (company = null, index = 0) => {
      const folder = await getFileByGovernanceAndFoldername(
         session.governance,
         session.assembly ? "Minutas" : "Convocatoria y minuta de reuniones"
      );
      const foundFolder = await getFoldersAndFilesById(folder.folder, session.company, session.governance);
      const filePDF = company
         ? await getUrlS3(
              "files-lecosy",
              {
                 folder: `gc/companies/${session.company}/governing-body/${session.governance}/files/${folder.folder}/${
                    foundFolder.folder.children.find((folder) => folder.name === company.person_details.comercialName)
                       ._id
                 }`,
              },
              `M${session.billCode}-${session.title}.pdf`
           )
         : await getUrlS3(
              "files-lecosy",
              {
                 folder: `gc/companies/${session.company}/governing-body/${session.governance}/files/${folder.folder}`,
              },
              `M${session.billCode}-${session.title}.pdf`
           );
      window.open(filePDF, "_blank", "noopener,noreferrer");
      if (company && groupCompaniesInSession.length === index) setDisabledPdf(false);
   };

   const handleLeaveSession = () => {
      navigate("/inicio");
   };

   const handleCloseManifiesto = () => {
      setManifiestoModalOpen(false);
   };

   const userCharge = useMemo(() => {
      if (!membersWithCharge) return [];
      if (membersWithCharge.length === 0) return [];
      const userCharges = membersWithCharge.find((member) => member._id === user.id);
      if (!userCharges?.memberCharge) return [];
      let charge = [];
      if (typeof userCharges?.memberCharge === "string") return [userCharges.memberCharge];
      for (const userCharge of userCharges.memberCharge) {
         if (userCharge.toLowerCase().includes("secretario")) charge.push("secretario");
         else if (userCharge.toLowerCase().includes("vicepresidente")) charge.push("vicepresidente");
         else if (userCharge.toLowerCase().includes("presidente")) charge.push("presidente");
         else if (userCharge.toLowerCase().includes("coordinador")) charge.push("coordinador");
         else charge.push(userCharge);
      }
      return charge;
   }, [membersWithCharge]);

   const hasSecretarySign = () => {
      if (userCharge?.includes("secretario")) return !alreadySign;
      else return true;
   };

   const alreadySigned = useMemo(
      () => signArray.includes(user.id) && !hasPermissions,
      [signArray?.length, hasPermissions]
   );

   if (isLoadingSession)
      return (
         <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
         </Box>
      );
   if (ableToLeave || session.completed)
      return (
         <Box sx={{ display: "flex" }}>
            <Stack sx={{ display: "flex", alignItems: "center", height: "100%", mt: "5%", width: "100%" }}>
               <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: 100 }} />
               <Typography sx={{ textAlign: "center", mt: 2, width: 346, fontSize: 16 }}>
                  {`Esta ${session?.assembly ? "asamblea" : "sesión"} ha sido finalizada.`}
               </Typography>
               {!session.group ? (
                  <Button
                     disabled={disabledPdf}
                     variant="contained"
                     sx={{ width: 160, mt: 4 }}
                     onClick={() => {
                        getUploadedSessionBill();
                     }}
                  >
                     Ver minuta
                  </Button>
               ) : (
                  <Stack spacing={2} my={2}>
                     {groupCompaniesInSession.map((company, i) => (
                        <Button
                           disabled={disabledPdf}
                           sx={{ bgcolor: "#4B5563", width: "220px", ":hover": { bgcolor: "#162c44" } }}
                           onClick={() => getUploadedSessionBill(company, i)}
                        >
                           <Typography sx={{ color: "white" }}>{company.person_details.comercialName}</Typography>
                        </Button>
                     ))}
                  </Stack>
               )}
            </Stack>
         </Box>
      );
   if (session.canceled)
      return (
         <Box sx={{ display: "flex" }}>
            <Stack sx={{ display: "flex", alignItems: "center", height: "100%", mt: "5%", width: "100%" }}>
               <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: 100 }} />
               <Typography sx={{ textAlign: "center", mt: 2, width: 346, fontSize: 16 }}>
                  {`Esta ${session?.assembly ? "asamblea" : "sesión"} ha sido cancelada`}
               </Typography>
               {hasPermissions && (
                  <Button
                     variant="contained"
                     sx={{ width: 160, mt: 4 }}
                     onClick={async () => {
                        if (session.group) await createGroupSessionPDF(null, true);
                        else await createPDF(null, true);
                     }}
                  >
                     Generar PDF
                  </Button>
               )}
            </Stack>
         </Box>
      );
   if (session.completed === false)
      return (
         <Box sx={{ display: "flex" }}>
            <Stack sx={{ display: "flex", alignItems: "center", height: "100%", mt: "5%", width: "100%" }}>
               <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: 100 }} />
               <Typography sx={{ textAlign: "center", mt: 2, width: 346, fontSize: 16 }}>
                  {`Esta ${
                     session?.assembly ? "asamblea" : "sesión"
                  } ha sido invalidada, el quórum requerido no ha sido completado`}
               </Typography>
               {hasPermissions && (
                  <Button
                     variant="contained"
                     sx={{ width: 160, mt: 4 }}
                     onClick={async () => {
                        if (session.group) await createGroupSessionPDF();
                        else await createPDF();
                     }}
                  >
                     Generar PDF
                  </Button>
               )}
            </Stack>
         </Box>
      );
   else if (waitingToVerify && !hasPermissions)
      return (
         <>
            <Typography sx={{ textAlign: "center", mt: 2 }}>Esperando verificación</Typography>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
               <Button
                  variant="contained"
                  disabled={timer > 0}
                  onClick={() => {
                     setManifiestoModalOpen(true);
                     setTimer(59);
                  }}
               >
                  {timer > 0 && `0:${timer < 10 ? "0" + timer : timer}`} Intentar de nuevo
               </Button>
            </Box>
            <Modal
               open={manifiestoModalOpen}
               onClose={handleCloseManifiesto}
               sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
               <ManifestoModal
                  session={session}
                  user={user}
                  gobernanceBody={sessionFound?.governance}
                  setManifiestoModalOpen={setManifiestoModalOpen}
                  isFromRejected={true}
               />
            </Modal>
         </>
      );
   else if (userRejected)
      return (
         <>
            <Stack spacing={2} sx={{ alignItems: "center" }}>
               <Typography sx={{ textAlign: "center", marginTop: 2 }}>
                  No se pudo verificar con éxito tu identificación, favor de intentar de nuevo.
               </Typography>
               <Button
                  variant="contained"
                  sx={{ bgcolor: "#162c44", width: "20%" }}
                  onClick={() => {
                     setManifiestoModalOpen(true);
                  }}
               >
                  Intentar de nuevo
               </Button>
            </Stack>
            <Modal
               open={manifiestoModalOpen}
               onClose={handleCloseManifiesto}
               sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
               <ManifestoModal
                  session={session}
                  user={user}
                  gobernanceBody={sessionFound?.governance}
                  setManifiestoModalOpen={setManifiestoModalOpen}
                  isFromRejected={true}
               />
            </Modal>
         </>
      );
   else {
      return (
         <>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  opacity: alreadySigned ? 0.5 : 1,
                  pointerEvents: alreadySigned ? "none" : "auto",
               }}
            >
               <Stack sx={{ width: "23%", gap: 1, height: 872 }}>
                  <SessionFiles />
                  <SessionNotes sessionId={session._id} />
               </Stack>
               <Bill />
               <Stack sx={{ width: "23%", gap: 2, height: 872 }}>
                  <Stack direction={"row"}>
                     <Button
                        fullWidth
                        sx={{
                           bgcolor: "#162c44",
                           border: 0.65,
                           borderColor: "#E0E0E0",
                           borderRadius: 3,
                           ":hover": { bgcolor: "#162c44" },
                           mr: myPendingVotes.length > 0 ? -10 : 0,
                        }}
                        onClick={handleClick}
                     >
                        <Typography sx={{ color: "white", fontWeight: 600, fontSize: 15 }}>
                           {state.vote !== null || votationOnCourse ? "Votación en curso" : "Mis votaciones"}
                        </Typography>
                     </Button>
                     {myPendingVotes.length > 0 && (
                        <ErrorIcon
                           sx={{
                              position: "relative",
                              right: -65,
                              top: -11,
                              color: "#ffcc00",
                              bgcolor: "white",
                              borderRadius: 50,
                              border: 0.5,
                           }}
                        />
                     )}
                  </Stack>
                  {hasPermissions ? <SessionVerifyMembers /> : <SessionCommitments />}
                  {isLoadingSession ? <CircularProgress /> : <SessionMembers />}
                  {ableToLeave && (
                     <Button
                        sx={{
                           bgcolor: "#dadbdd",
                           border: 0.65,
                           borderColor: "#E0E0E0",
                           borderRadius: 3,
                           display: "flex",
                           alignItems: "center",
                           gap: 1,
                        }}
                        onClick={handleLeaveSession}
                     >
                        <ExitToAppIcon sx={{ color: "#152C44", fontSize: 18 }} />
                        <Typography sx={{ color: "#152c44", fontWeight: 600, fontSize: 15 }}>
                           Salir de la sesión
                        </Typography>
                     </Button>
                  )}
                  {missingSigns && (
                     <Typography sx={{ fontSize: 12, color: "red", textAlign: "justify" }}>
                        Una vez se hayan marcado como firmado todos los miembros de la sesión en la minuta, favor de
                        volver a dar click en el botón de "Finalizar sesión" para terminar sesión.
                     </Typography>
                  )}
                  {hasPermissions && (
                     <>
                        <Button
                           sx={{
                              bgcolor: "#E1E1E1",
                              border: 0.65,
                              borderColor: "#EFEFEF",
                              borderRadius: 3,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                           }}
                           onClick={() => {
                              setOpenConfirmCancelSession(true);
                           }}
                        >
                           <NotInterestedIcon sx={{ color: "#5A5B5D", fontSize: 18 }} />
                           <Typography sx={{ color: "#5A5B5D", fontWeight: 600, fontSize: 15 }}>
                              Cancelar sesión
                           </Typography>
                        </Button>
                        <LoadingButton
                           isLoading={loadingFinishedSession}
                           disableSaveIcon
                           sx={{
                              bgcolor: "#dadbdd",
                              border: 0.65,
                              borderColor: "#E0E0E0",
                              borderRadius: 3,
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              color: "#152c44",
                              "&:hover": {
                                 bgcolor: "#E0E0E0",
                              },
                           }}
                           variant="text"
                           disabled={isLoadingBill}
                           onClick={handleEndSession}
                        >
                           <ExitToAppIcon sx={{ color: "#152C44", fontSize: 18 }} />
                           <Typography sx={{ color: "#152c44", fontWeight: 600, fontSize: 15 }}>
                              Finalizar sesión
                           </Typography>
                        </LoadingButton>
                     </>
                  )}
               </Stack>
               <Modal open={openModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Box
                     sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 500,
                        boxShadow: 24,
                        borderRadius: 2,
                     }}
                  >
                     <NewVotesModal
                        votationOnCourse={votationOnCourse}
                        setVotationOnCourse={setVotationOnCourse}
                        setOpenModal={setOpenModal}
                     />
                  </Box>
               </Modal>
               <Modal
                  open={
                     openModalCreateModal &&
                     ((userCharge?.includes("coordinador") && userCharge?.length > 1) ||
                        !userCharge?.includes("coordinador"))
                  }
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
               >
                  <Box
                     sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 368,
                        boxShadow: 24,
                        borderRadius: 2,
                        zIndex: 1000,
                     }}
                  >
                     <VoteModal user={user} setOpenModalCreateModal={setOpenModalCreateModal} />
                  </Box>
               </Modal>
               <Modal open={openModalToSign && hasSecretarySign()}>
                  <Box
                     sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "40%",
                        boxShadow: 24,
                        borderRadius: 2,
                     }}
                  >
                     <SignModal setOpenModalToSign={setOpenModalToSign} session={session} />
                  </Box>
               </Modal>
               <Modal
                  open={openConfirmCancelSession}
                  onClose={() => {
                     setOpenConfirmCancelSession(false);
                  }}
               >
                  <Box
                     sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "40%",
                        boxShadow: 24,
                        borderRadius: 2,
                        maxWidth: 512,
                     }}
                  >
                     <CancelSessionModal
                        setOpenConfirmCancelSession={setOpenConfirmCancelSession}
                        createPDF={session.group ? createGroupSessionPDF : createPDF}
                     />
                  </Box>
               </Modal>
               <Modal open={!isSocketConnected}>
                  <Box
                     border={0}
                     sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "40%",
                        borderRadius: 2,
                        maxWidth: 512,
                     }}
                  >
                     <Stack>
                        <Typography
                           textAlign={"center"}
                           fontSize={"20px"}
                           fontWeight={600}
                           letterSpacing={1.3}
                           sx={{ color: "white" }}
                        >
                           Reconectando...
                        </Typography>
                     </Stack>
                  </Box>
               </Modal>
            </Box>
            {alreadySigned && (
               <Typography
                  sx={{
                     fontWeight: 600,
                     fontSize: 30,
                     m: "auto",
                     color: "#162c44",
                     position: "fixed",
                     top: "50%",
                     left: "50%",
                     transform: "translate(-50%, -50%)",
                     width: "40%",
                     maxWidth: 512,
                     textAlign: "center",
                  }}
               >
                  {`EN ESPERA DE FINALIZACIÓN DE ${session.assembly ? "ASAMBLEA" : "SESIÓN"}`}
               </Typography>
            )}
         </>
      );
   }
};
function showSnackBar(arg0: string, arg1: boolean) {
   throw new Error("Function not implemented.");
}
