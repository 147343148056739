import ApiClient from "./Config/apiClient";

const base_url =
   window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
      ? "https://test.server.lecosy.com.mx/sb"
      : process.env.NODE_ENV === "production"
      ? "https://server.lecosy.com.mx/sb"
      : "http://localhost:8005";

interface IDocument_Sign {
   name: string;
   file: string;
   signers?: ISigners[];
}

interface ISigners {
   fullname: string;
   email: string;
   phone?: string;
   type: string;
}

class SignService extends ApiClient {
   constructor() {
      super(base_url + "/sign");
   }

   async fetchDocumentById(document_id: string) {
      return this.get("/get/doc/" + document_id);
   }

   async createDocument(data: IDocument_Sign) {
      return this.post("/create/doc", data);
   }

   async deleteDocument(document_id: string) {
      return this.delete("/delete/doc/" + document_id);
   }

   async downloadDocument(document_id: string) {
      return this.get("/download/doc/" + document_id);
   }

   async downloadExpedient(document_id: string) {
      return this.get("/download/exp/" + document_id, { responseType: "blob" });
   }

   async addSignerToDocument(document_id: string, data: ISigners[]) {
      return this.post("/add/signer/" + document_id, data);
   }

   async getSignerFromDocument(document_id: string) {
      return this.get("/get/signer/" + document_id);
   }
}

export default new SignService();
