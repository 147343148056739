import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { PeopleAltOutlined } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/userContext";
import { createCommentCommitment, updateCommitmentStatus, useGetResourceSelected } from "../../../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../../../context/governanceContext/governanceContext";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import { CommentsComponent } from "./Components/CommentsComponent";
import { getDateFromISODate } from "../../../../../const/globalConst";
import { StatusComponent } from "../ComponentsForTaskList";
import { longTextValidation } from "../../../../../lib/validations/inputSchemas";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import LoginModal from "../../../../LoginModal";

interface ModalHeaderComponentProps {
   open: boolean;
   setOpen: Function;
   commitment?: any;
   users?: any[];
}

export const CommitmentModal = (props: ModalHeaderComponentProps) => {
   const { user } = useContext(UserContext);
   const { refetchTasks, companySelected, setRefetchTasks } = useContext(GovernanceContext);
   const resourceId = useGetResourceSelected();
   const [comments, setComments] = useState(props.commitment ? props.commitment?.comments : []);
   const [anchorEl, setAnchorEl] = useState(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const statusOptions = [
   { value: "PENDING"},
   { value: "PROCESS"},
   { value: "REVIEW"},
   { value: "COMPLETE"},
   ];

   useEffect(() => {
      setComments(props.commitment?.comments);
   }, [props.commitment]);

   const handleSubmitComment = async (comment) => {
      let Obj: any = {};
      if (comment === "") return;
      try {
         Obj = {
            commentBody: { user: user.id, userName: `${user.firstName} ${user.lastName}`, content: comment },
            commitmentId: props.commitment._id,
         };
         await createCommentCommitment(Obj);
         setRefetchTasks(!refetchTasks);
         setComments([...comments, Obj.commentBody]);
         comment = "";
      } catch (error) {
         console.log(error);
      }
   };

   const formatDate = (dateStr: string): string => {
      const parts = dateStr.split("/");
      const thirdNumber = parseInt(parts[2], 10);
      return thirdNumber > 2999 ? "Indeterminado" : dateStr;
   };

   async function handleStatusChange(value: string) {
      try {
        await  updateCommitmentStatus(props.commitment._id, value, companySelected, resourceId)
          .then(() => {
            setRefetchTasks((prev) => !prev);
          })
      } catch (error) {
        console.error("Error inesperado al actualizar el estatus:", error);
        showSnackBar("Error inesperado al actualizar el estatus", true);
      }
    } 
   

   return (
      <CustomModalComponent
         open={props.open}
         setOpen={props.setOpen}
         title={props.commitment ? "Detalles del acuerdo y compromiso" : "Registro de tarea nueva"}
      >
         {props.commitment && (
            <Box
               sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: 650,
                  p: 4,
                  maxHeight: 500,
                  overflowY: "auto",
                  rowGap: 2,
               }}
            >
               <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                  <Typography variant="body2" fontWeight={600}>
                     Nombre:
                  </Typography>
                  <Typography variant="body2">{props.commitment.title}</Typography>
               </Box>
               <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                     <Typography variant="body2" fontWeight={600}>
                        Fecha límite:
                     </Typography>
                     <Typography variant="body2">
                        {props.commitment.dueDate ? formatDate(getDateFromISODate(props.commitment.dueDate)) : ""}
                     </Typography>
                  </Box>
                  <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                     <Typography variant="body2" fontWeight={600}>
                        Estatus:
                     </Typography>
                     <Box>
                        <Box
                           sx={{ cursor: "pointer" }}
                           onClick={(event) =>{ setAnchorEl(event.currentTarget);}}
                        >
                           <StatusComponent status={props.commitment.status} updatedAt={props.commitment.updatedAt} dueDate={props.commitment.dueDate} />
                        </Box>
                        <Menu
                           anchorEl={anchorEl} 
                           open={Boolean(anchorEl)} 
                           onClose={() => setAnchorEl(null)} 
                           anchorOrigin={{
                              vertical: "top",
                              horizontal: "left", 
                           }}
                           transformOrigin={{
                              vertical: "top", 
                              horizontal: "left",
                           }}
                           PaperProps={{
                              sx: {
                                 marginLeft: "-20px",
                              },
                           }}
                        >
                           {statusOptions .filter((status) => {
                                 if (props.commitment.status === "EXPIRED") {
                                    return status.value === "COMPLETE";
                                 }
                                 return true;
                              }).map((status) => (
                           <MenuItem
                              key={status.value}
                              onClick={async () => {
                                await handleStatusChange(status.value);
                                props.commitment.status = status.value;
                                 setAnchorEl(null); 
                              }}
                           >
                              <StatusComponent status={status.value} updatedAt={props.commitment.updatedAt} dueDate={props.commitment.dueDate} />
                           </MenuItem>
                           ))}
                        </Menu>
                     </Box>
                  </Box>
               </Box>
               <Box sx={{ display: "flex", columnGap: 1, alignItems: "center" }}>
                  <Typography variant="body2" fontWeight={600}>
                     {props.commitment?.session?.billCode}:
                  </Typography>
                  <Typography variant="body2">{props.commitment?.session?.title}</Typography>
               </Box>

               {props.users.length > 0 ? (
                  <>
                     <Typography variant="body2" fontWeight={600}>
                        Asignada a
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           columnGap: 2,
                           flexWrap: "wrap",
                           rowGap: 1,
                        }}
                     >
                        <PeopleAltOutlined sx={{ fontSize: 15 }} />
                        {props.users
                           .filter((u) => props.commitment?.users.includes(u.id) || props.commitment?.owner === u.id)
                           .map((e, i) => (
                              <Typography key={i} fontSize={"14px"}>
                                 {e.user}
                              </Typography>
                           ))}
                     </Box>
                  </>
               ) : (
                  <Typography variant="body2" fontWeight={600}>
                     Sin asignar
                  </Typography>
               )}

               <Formik
                  initialValues={{
                     comment: "",
                  }}
                  onSubmit={handleSubmitComment}
                  validationSchema={longTextValidation}
               >
                  <Form>
                     <CommentsComponent users={props.users} comment={comments} submitComment={handleSubmitComment} />
                  </Form>
               </Formik>
            </Box>
         )}
      </CustomModalComponent>
   );
};
