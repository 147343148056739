import React, { useContext } from "react";
import { SystemThemeConfig } from "../../../config/systemTheme.config";
import ScreenHeaderExternal from "../../../components/Gobierno corporativo/ExternalBill/ScreenHeaderExternal";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { logo_Isotipo } from "../../../assets/icons/DrawerAppScreenIcons";
import { ExternalLegalarioContext } from "../../../context/governanceContext/ExternalLegalarioContext";
import MobileAuthentication from "../../../components/Gobierno corporativo/Legalario/MobileAuthentication";
import { CheckCircle } from "@mui/icons-material";

const LegalarioMobile = () => {
   const { isLoading, completedProcess } = useContext(ExternalLegalarioContext);

   return (
      <SystemThemeConfig>
         <ScreenHeaderExternal />
         <Box
            sx={{
               height: "100vh",
               overflowY: "auto",
               bgcolor: "#F5F5F5",
               p: { xs: 0, lg: 5 },
               pt: { xs: 8, lg: 12 },
            }}
         >
            {isLoading ? (
               <Box
                  sx={{
                     width: "100%",
                     height: "100%",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                     py: 2,
                  }}
               >
                  <CircularProgress />
               </Box>
            ) : completedProcess ? (
               <Stack
                  direction={"column"}
                  spacing={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  width={"100%"}
                  height={"80vh"}
                  sx={{ opacity: 0.5, px: 5 }}
               >
                  <CheckCircle
                     sx={{
                        width: "18vw",
                        height: "18vw",
                        color: "#162c44",
                     }}
                  />
                  <Typography
                     fontWeight={600}
                     fontSize={"20px"}
                     marginLeft={1}
                     py={0.5}
                     sx={{ borderBottom: 2, borderBottomColor: "#162c44", textAlign: "center" }}
                  >
                     Proceso terminado correctamente, puede cerrar esta ventana.
                  </Typography>
               </Stack>
            ) : (
               <div>
                  <Stack
                     direction={"column"}
                     spacing={3}
                     display={"flex"}
                     justifyContent={"center"}
                     alignItems={"center"}
                     width={"100%"}
                     height={"80vh"}
                     sx={{ opacity: 0.5, px: 5 }}
                  >
                     <Box
                        sx={{
                           width: "18vw",
                           height: "18vw",
                           backgroundImage: `url(${logo_Isotipo})`,
                           backgroundPosition: "center center",
                           backgroundSize: "cover",
                        }}
                     />
                     <Typography
                        fontWeight={600}
                        fontSize={"20px"}
                        marginLeft={1}
                        py={0.5}
                        sx={{ borderBottom: 2, borderBottomColor: "#162c44", textAlign: "center" }}
                     >
                        "La solución en materia de gestión de riesgo y cumplimiento."
                     </Typography>
                  </Stack>
                  {MobileAuthentication()}
               </div>
            )}
         </Box>
      </SystemThemeConfig>
   );
};

export default LegalarioMobile;
