import { AppBar, Box, Link, Toolbar } from "@mui/material";
import React from "react";
import { logo_Isotipo_Blanco } from "../../../assets/icons/DrawerAppScreenIcons";

const ScreenHeaderExternal = () => {
   return (
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#162C44", boxShadow: 0 }}>
         <Toolbar>
            <Box sx={{ width: "100%", bgcolor: "#162C44" }}>
               <Link href={"https://blog.lecosy.com.mx"} target="_blank" rel="noopener noreferrer">
                  <Box
                     src={logo_Isotipo_Blanco}
                     component={"img"}
                     sx={{
                        ml: -1,
                        mt: 1,
                        alignSelf: "center",
                        maxWidth: 42,
                        maxHeight: 42,
                     }}
                     alt="Logo Lecosy"
                  />
               </Link>
            </Box>
         </Toolbar>
      </AppBar>
   );
};

export default ScreenHeaderExternal;
