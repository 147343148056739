import { createContext, useCallback, useEffect, useReducer, useState } from "react";
import { Users } from "../../types/BaseTypes";
import { ISession } from "../../types/governance.types";
import { useParams } from "react-router-dom";
import { createExternalSessionToken, getSessionByIdExternal } from "../../lib/gobCorpBEClient";
import { io } from "socket.io-client";
import { getUserByUserId } from "../../lib/usersBEClient";

interface ExternalLegalarioContextType {
   user: any;
   session: ISession;
   socket: any;
   isLoading: boolean;
   token: string;
   completedProcess: boolean;
   setCompletedProcess: Function;
}

interface ExternalLegalarioState {
   user: Users;
   session: ISession;
   socket: any;
   token: string;
}

const initialState: ExternalLegalarioState = {
   user: null,
   session: null,
   socket: null,
   token: null,
};

function userReducer(state: ExternalLegalarioState, action) {
   switch (action.type) {
      case "SET_USER":
         return { ...state, user: action.payload };
      case "SET_SESSION":
         return { ...state, session: action.payload };
      case "SET_SOCKET":
         return { ...state, socket: action.payload };
      case "SET_TOKEN":
         return { ...state, token: action.payload };
      default:
         return state;
   }
}

export const ExternalLegalarioContext = createContext<ExternalLegalarioContextType | undefined>(undefined);

export const ExternalLegalarioContextProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);
   //#region Dispatch
   const setUser = useCallback((state: ExternalLegalarioState) => {
      dispatch({ type: "SET_USER", payload: state });
   }, []);

   const setSession = useCallback((state: ExternalLegalarioState) => {
      dispatch({ type: "SET_SESSION", payload: state });
   }, []);

   const setSocket = useCallback((state: any) => {
      dispatch({ type: "SET_SOCKET", payload: state });
   }, []);

   const setToken = useCallback((state: ExternalLegalarioState) => {
      dispatch({ type: "SET_TOKEN", payload: state });
   }, []);

   //#end region

   const base_url =
      window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
         ? "https://test.server.lecosy.com.mx"
         : process.env.NODE_ENV === "production"
         ? "https://server.lecosy.com.mx"
         : "http://localhost:8003";

   useEffect(() => {
      try {
         const socketServer = io(base_url, {
            path:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? "/gc/socket.io"
                  : "/socket.io",
            port:
               process.env.NODE_ENV === "production" ||
               window.location.hostname === "test.web.lecosy.com.mx" ||
               window.location.hostname === "www.test.web.lecosy.com.mx"
                  ? 80
                  : 8003,
            withCredentials: true,
         });
         setSocket(socketServer);
         return () => {
            socketServer.disconnect();
         };
      } catch (e) {
         setSocket(null);
      }
   }, [base_url]);

   useEffect(() => {
      if (!state.session) return;
      state.socket.emit("join", { sessionId: state.session._id, userId: userId });
   }, [state.session]);

   const [isLoading, setIsLoading] = useState(false);
   const [completedProcess, setCompletedProcess] = useState(false);
   const { userId, sessionId } = useParams();

   const fetchData = useCallback(async () => {
      setIsLoading(true);

      try {
         const { session, accessToken } = await getSessionByIdExternal(sessionId, userId);
         setSession(session);
         setToken(accessToken);
         const userFound = await getUserByUserId(userId, accessToken);
         setUser(userFound);
      } catch (error) {
         console.error("Error fetching data:", error);
      } finally {
         setIsLoading(false);
      }
   }, [sessionId, userId]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   useEffect(() => {
      const verifyToken = async () => {
         const { accessToken } = await createExternalSessionToken(sessionId, userId);
         setToken(accessToken);
      };
      verifyToken();
      const intervalId = setInterval(verifyToken, 1000 * 10 * 5);
      return () => clearInterval(intervalId);
   }, []);

   return (
      <ExternalLegalarioContext.Provider
         value={{
            ...state,
            isLoading,
            completedProcess,
            setCompletedProcess,
         }}
      >
         {children}
      </ExternalLegalarioContext.Provider>
   );
};
