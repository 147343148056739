export const legalario = () => {
   const environment =
      window.location.hostname === "test.web.lecosy.com.mx" || window.location.hostname === "www.test.web.lecosy.com.mx"
         ? "development"
         : process.env.NODE_ENV === "production"
         ? "production"
         : "development";

   const resources = {
      development: {
         organizationId: "667b362fadada259ac2faf78",
         apiKey: "9bcdd0595e6faf4c15caabce560ff7e8f0a9b56fbcf7e6374d372046fa3bcc40",
         env: "SANDBOX",
      },
      production: {
         organizationId: "663e872071b1752fa5339ad3",
         apiKey: "9bcdd0595e6faf4c15caabce560ff7e8f0a9b56fbcf7e6374d372046fa3bcc40",
         env: "PRODUCTION",
      },
   };

   let config;

   if (window["LegalarioSDK"]) {
      try {
         config = new window["LegalarioSDK"]({
            ...resources[environment],
            theme: {
               primaryColor: "#162c44",
               secondaryColor: "#1d2c44",
            },
         });
      } catch (error) {
         console.error("Error initializing LegalarioSDK:", error);
      }
   } else {
      console.error("LegalarioSDK is not available on the window object.");
   }

   return config;
};
