import axios, { Axios } from "axios";

class ApiClient {
   client: Axios;
   constructor(baseURL) {
      this.client = axios.create({
         baseURL: baseURL,
         timeout: 10000,
         withCredentials: true,
      });

      this.client.interceptors.request.use(
         (config) => {
            return config;
         },
         (error) => {
            return Promise.reject(error);
         }
      );

      this.client.interceptors.response.use(
         (response) => response,
         (error) => {
            return Promise.reject(error);
         }
      );
   }

   async get(url, config = {}) {
      const response = await this.client.get(url, config);
      return response.data;
   }

   async post(url, data, config = {}) {
      const response = await this.client.post(url, data, config);
      return response.data;
   }

   async put(url, data, config = {}) {
      const response = await this.client.put(url, data, config);
      return response.data;
   }

   async delete(url, config = {}) {
      const response = await this.client.delete(url, config);
      return response.data;
   }
}

export default ApiClient;
